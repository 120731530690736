import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { CnUploadView } from './components/cn-upload';
import { CnMiniUploadView, } from './components/cn-mini-upload';
import { CnDraggerUploadView, } from './components/cn-dragger-upload';
import './index.scss';
const CnUpload = withI18n(CnUploadView, {
    locale,
    componentName: 'CnUpload',
});
const CnMiniUpload = withI18n(CnMiniUploadView, {
    locale,
    componentName: 'CnUpload',
});
const CnDraggerUpload = withI18n(CnDraggerUploadView, {
    locale,
    componentName: 'CnUpload',
});
export { CnUpload, CnMiniUpload, CnDraggerUpload };
