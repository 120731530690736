import * as React from 'react';
import { Radio as NextRadio } from '@fusion/lib';
import $i18n from 'panda-i18n';
import { CnReadOnly } from '@/components/cn-read-only';
import { useCnRequest } from '@/components/cn-utils';
import locale from '@/locales';
const renderPreview = (value, readOnlyProps) => {
    let showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return React.createElement(CnReadOnly, { value: showValue, type: "enum", ...readOnlyProps });
};
function withI18n(c, p) {
    $i18n.init(p);
    return c;
}
export const CnRadioGroup = withI18n(React.forwardRef((props, ref) => {
    const { readOnly, readOnlyProps, requestConfig = {}, ...otherProps } = props;
    const insertProps = {};
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    const { data = [] } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    if (isRemoteDataSource) {
        insertProps.dataSource = data;
    }
    return (React.createElement(NextRadio.Group, { "data-name": "CnRadio", ref: ref, isPreview: readOnly, renderPreview: (values) => renderPreview(values, readOnlyProps), ...insertProps, ...otherProps }));
}), {
    locale,
    componentName: 'CnRadio',
    forwardRef: true,
});
CnRadioGroup.displayName = 'CnRadio';
CnRadioGroup.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
