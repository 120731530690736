import * as React from 'react';
// import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
// window = window as WindowType;
export class Plugin {
    constructor(componentName, pluginManager) {
        this.componentName = '';
        this.plugin = {};
        this.basicsPlugin = [];
        this.localPlugin = [];
        this.globalPlugin = [];
        this.setGlobalPlugin = (globalPlugin = []) => {
            if (Array.isArray(globalPlugin)) {
                this.globalPlugin = globalPlugin;
            }
            else if (isPlainObject(globalPlugin)) {
                this.globalPlugin = Object.values(globalPlugin);
            }
        };
        this.pluginManager = pluginManager;
        this.componentName = componentName;
        pluginManager.getGlobalPlugin();
    }
    setBasicsPlugin(basicsPlugin) {
        if (Array.isArray(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(basicsPlugin);
        }
        else if (isPlainObject(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(Object.values(basicsPlugin));
        }
    }
    setLocalPlugin(localPlugin = []) {
        if (typeof localPlugin === 'string') {
            const plugin = this.pluginManager.getPluginByPluginName(localPlugin, this.componentName);
            if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                this.localPlugin = [plugin.method];
            }
        }
        if (Array.isArray(localPlugin)) {
            this.localPlugin = localPlugin.reduce((pre, item) => {
                if (isFunction(item)) {
                    pre.push(item);
                }
                else if (isPlainObject(localPlugin)) {
                    pre.push(...Object.values(localPlugin));
                }
                else if (typeof item === 'string') {
                    const plugin = this.pluginManager.getPluginByPluginName(item, this.componentName);
                    if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                        pre.push(plugin.method);
                    }
                }
                return pre;
            }, []);
        }
        else if (isPlainObject(localPlugin)) {
            this.localPlugin = Object.values(localPlugin);
        }
    }
    getPlugin() {
        return this.basicsPlugin.concat(this.localPlugin).concat(this.globalPlugin);
    }
}
// 自动注册到全局的插件
//
class PluginManager {
    constructor() {
        this.plugin = {};
        this.registeredPlugin = {};
        this.getGlobalPlugin();
    }
    getGlobalPlugin() {
        const plugin = window.CN_UI_PLUGIN || [];
        this.plugin = plugin.reduce((plugin, { name, plugin: pluginFun, plugins: pluginFuns }) => {
            plugin[name] = Array.isArray(plugin[name])
                ? plugin[name].concat(pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun)
                : pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun;
            return plugin;
        }, {});
    }
    registerPlugin(pluginList) {
        var _a;
        const registerPlugin = (_a = pluginList === null || pluginList === void 0 ? void 0 : pluginList.map) === null || _a === void 0 ? void 0 : _a.call(pluginList, (plugin) => {
            return {
                name: plugin.componentName,
                plugins: plugin.plugins.map((item) => ({
                    name: item === null || item === void 0 ? void 0 : item.name,
                    method: item.sourceCode,
                    lowCodeConfig: item.lowCode,
                    oneCode: item.oneCode,
                })),
            };
        });
        window.CN_UI_PLUGIN = Array.isArray(window.CN_UI_PLUGIN)
            ? window.CN_UI_PLUGIN.concat(...registerPlugin)
            : registerPlugin;
    }
    loadPlugins(componentName) {
        if (Array.isArray(componentName)) {
            componentName.forEach((name) => {
                this.registeredPlugin[name] = this.plugin[name] || [];
            });
        }
        else {
            this.registeredPlugin[componentName] = this.plugin[componentName] || [];
        }
    }
    loadFullPlugins() {
        this.registeredPlugin = { ...this.plugin };
    }
    fullRegisterPlugins() {
        this.loadFullPlugins();
    }
    // getPluginsByComponentName(componentName) {
    getPluginsByComponentName(componentName) {
        var _a;
        if (Array.isArray(componentName)) {
            return componentName.reduce((plugin, name) => {
                return plugin.concat(this.registeredPlugin[name] || []);
            }, []);
            // .map((item) => item.method);
        }
        return (_a = this.registeredPlugin[componentName]) !== null && _a !== void 0 ? _a : [];
    }
    // getPluginsByPluginName(pluginName) {
    getPluginByPluginName(pluginName, componentName) {
        var _a, _b;
        const componentPlugin = (_a = this.plugin[componentName]) !== null && _a !== void 0 ? _a : [];
        const fullPluginList = Object.values(this.plugin).flat();
        const pluginList = componentName ? componentPlugin : fullPluginList;
        return (_b = pluginList === null || pluginList === void 0 ? void 0 : pluginList.find) === null || _b === void 0 ? void 0 : _b.call(pluginList, (item) => item.name === pluginName);
    }
}
export const pluginManager = new PluginManager();
export function takeExpandPropsFormPlugins(plguins, props) {
    return plguins.reduce((expandProps, handleFun) => {
        return Object.assign(expandProps, handleFun(expandProps));
    }, { ...props });
}
export function HocBaseComponents(BaseComponent, { props, plugins = [], ref, dataName }) {
    var _a;
    const baseComponentRef = React.useRef();
    // console.log(reffield.current, 'reffield.current1');
    // function HocComponet({
    //   BaseComponent,
    //   plguins = [],
    //   ref,
    //   dataName,
    //   ...prpos
    // }) {
    const expandProps = plugins === null || plugins === void 0 ? void 0 : plugins.reduce((expandProps, handleFun) => {
        const { props, context, render } = handleFun(expandProps.props, expandProps.context);
        if (isFunction(render) && !baseComponentRef.current) {
            const { BaseComponent } = expandProps;
            expandProps.BaseComponent = (props) => {
                return render(props, BaseComponent);
            };
        }
        // Object.assign(expandProps.props, props);
        // Object.assign(expandProps.context, context);
        return {
            ...expandProps,
            props,
            context,
        };
    }, {
        props: { ...props },
        context: {},
        BaseComponent,
    });
    baseComponentRef.current =
        (_a = baseComponentRef.current) !== null && _a !== void 0 ? _a : expandProps.BaseComponent;
    const ExpandBaseComponent = baseComponentRef.current;
    if (dataName) {
        expandProps.props['data-name'] = dataName;
    }
    if (baseComponentRef.current) {
        return React.createElement(ExpandBaseComponent, { ref: ref, ...expandProps.props });
    }
    return React.createElement(BaseComponent, { ref: ref, ...expandProps.props });
    // }
    // return (
    //   <HocComponet
    //     BaseComponent={BaseComponent}
    //     plguins={plguins}
    //     ref={ref}
    //     dataName={dataName}
    //     {...props}
    //   />
    // );
}
