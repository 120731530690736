import * as React from 'react';
import { formatCardNumber, isNumberStr, isPlainObject, } from './utils/index';
import './index.scss';
import { Progress, Box, Balloon } from '@fusion/lib';
import classnames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnOSSUpload } from '@/components/cn-oss-upload';
import { domStyleMap } from '@/components/cn-utils';
import { CnAsyncSelect } from '@/components/cn-async-select';
import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import pick from 'lodash/pick';
const { Tooltip } = Balloon;
// interface ProgressFormatProps extends CnTableCellFormatProps {
//   // shape: 'circle' | 'line' | undefined;
//   // @override
//   state: 'normal' | 'success' | 'error' | undefined;
// }
const CnTableCellFormat = {
    tag: {
        cell(val) {
            if (Array.isArray(val)) {
                return (React.createElement(Box, { spacing: 5, wrap: true, direction: "row", align: "center" }, val.map((v) => getTag(v))));
                // return val.map((v) => getTag(v));
            }
            // return <Tag type="primary">{val}</Tag>
            return getTag(val);
        },
    },
    number: {
        align: 'right',
        cell(value) {
            if (isNil(value) || value === '') {
                return '- -';
            }
            const valueNumber = new BigNumber(value);
            const valueTypeVerify = typeof value === 'string' || typeof value === 'number';
            const valueIsLikeNumber = typeof valueNumber.toNumber() === 'number';
            const canMicrometer = valueTypeVerify && valueIsLikeNumber;
            if (canMicrometer) {
                return valueNumber.toFormat();
            }
            return value;
        },
    },
    currency: {
        align: 'right',
        cell(val) {
            return getNumFromStr(val);
        },
    },
    'currency-with-unit': () => {
        return {
            align: 'right',
            cell(val) {
                if (isPlainObject(val)) {
                    const amount = val === null || val === void 0 ? void 0 : val.amount;
                    const currency = val === null || val === void 0 ? void 0 : val.currency;
                    return `${getNumFromStr(amount)}  ${currency}`;
                }
                else if (val !== undefined &&
                    val !== null &&
                    (typeof val === 'string' || typeof val === 'number')) {
                    return getNumFromStr(val);
                }
            },
        };
    },
    percent: {
        align: 'right',
        cell(val) {
            return `${val}%`;
        },
    },
    user: {
        cell(value) {
            let result;
            function renderUserOne(value) {
                if (isPlainObject(value)) {
                    let nameDom;
                    let nickNameDom;
                    let workNoDom;
                    const { name, nickName, workNo } = value;
                    if (name) {
                        nameDom = name;
                    }
                    if (nickName) {
                        nickNameDom = `(${nickName})`;
                    }
                    if (workNo) {
                        workNoDom = workNo;
                    }
                    const dom = (React.createElement("div", { className: "table-format-user-content" },
                        React.createElement("img", { src: `https://work.alibaba-inc.com/photo/${workNo}.25x25.jpg`, className: 'table-format-user-avatar' }),
                        nameDom,
                        nickNameDom));
                    if (workNoDom) {
                        return (React.createElement(CnTooltip, { align: 't', trigger: dom }, workNoDom));
                    }
                    else {
                        return dom;
                    }
                }
            }
            if (Array.isArray(value)) {
                result = value.map((item) => {
                    return renderUserOne(item);
                });
            }
            else if (isPlainObject(value)) {
                result = renderUserOne(value);
            }
            return React.createElement("div", { className: "table-format-user-wrap" }, result);
        },
    },
    'graphics-percent': (column) => {
        return {
            align: 'left',
            cell(val) {
                return React.createElement(Progress, { ...column.percentProps, percent: val });
            },
        };
    },
    progress: () => {
        return {
            align: 'left',
            cell(val) {
                if (typeof val !== 'object') {
                    val = { value: val };
                }
                return Number.isNaN(Number(val === null || val === void 0 ? void 0 : val.value)) ? ('') : (React.createElement(Progress, { className: "cn-table-cell-format-progress", percent: Number(val === null || val === void 0 ? void 0 : val.value), state: val === null || val === void 0 ? void 0 : val.state, textRender: (percent) => Number.isNaN(Number(percent)) ? '-%' : `${percent}%` }));
            },
        };
    },
    'multiple-text': () => {
        return {
            align: 'left',
            cell(val) {
                return (React.createElement("div", { className: 'cn-table-cell-format-multiple-text' }, Array.isArray(val)
                    ? val.map((item) => {
                        return renderLine(item);
                    })
                    : renderLine(val)));
            },
        };
    },
    'image-text': (c) => {
        return {
            align: 'left',
            cell(val) {
                if (isPlainObject(val)) {
                    const { url = '', title = '', content = '' } = val;
                    return (React.createElement("div", { className: 'cn-table-cell-format-image-text' },
                        React.createElement("img", { className: 'ctfit-img', src: url }),
                        React.createElement("div", { className: "ctfit-right" },
                            React.createElement("div", { className: "ctfit-title" }, renderLine(title)),
                            React.createElement("div", { className: "ctfit-content" }, renderLine(content, 2)))));
                }
                else if (typeof val === 'string') {
                    return React.createElement("img", { className: 'ctfit-img', src: val });
                }
                else {
                    return '';
                }
            },
        };
    },
    date(column) {
        return {
            cell(val) {
                var _a;
                if (val) {
                    return formatDate(val, (_a = column === null || column === void 0 ? void 0 : column.dateInterv) !== null && _a !== void 0 ? _a : '-', column === null || column === void 0 ? void 0 : column.dateFormat);
                    // return formatDate(val, column?.dateInterv ?? '-');
                }
                else {
                    return '- -';
                }
            },
        };
    },
    'date-time': (column) => {
        return {
            cell(val) {
                var _a, _b;
                if (val) {
                    return formatDateTime(val, {
                        interv: (_a = column === null || column === void 0 ? void 0 : column.dateInterv) !== null && _a !== void 0 ? _a : '-',
                        hasSecond: (_b = column === null || column === void 0 ? void 0 : column.dateHasSecond) !== null && _b !== void 0 ? _b : false,
                    });
                }
                else {
                    return '- -';
                }
            },
        };
    },
    file() {
        return {
            cell(cell) {
                return React.createElement(CnOSSUpload, { value: cell, readOnly: true, shape: "mini" });
            },
        };
    },
    bank(column) {
        return {
            cell(val) {
                return formatCardNumber(`${val}`, column.cardTypes);
            },
        };
    },
    ellipsis(column) {
        return {
            cell(val) {
                const isEmptyValue = isNil(val) || val === '';
                const text = isEmptyValue ? '- -' : val;
                const style = (column === null || column === void 0 ? void 0 : column.width)
                    ? { width: parseInt(column.width) - 30 }
                    : {};
                return React.createElement(CnEllipsis, { style: style }, text);
            },
        };
    },
    micrometer() {
        return {
            cell(value) {
                const valueNumber = new BigNumber(value);
                const valueTypeVerify = typeof value === 'string' || typeof value === 'number';
                const valueIsLikeNumber = typeof valueNumber.toNumber() === 'number';
                const canMicrometer = valueTypeVerify && valueIsLikeNumber;
                if (canMicrometer) {
                    return valueNumber.toFormat();
                }
                return value;
            },
        };
    },
    text() {
        return {
            cell(value) {
                // if (typeof value === 'string') {
                // }
                if (isPlainObject(value) && typeof value === 'object') {
                    // 对象传入，目前支持ToolTips,link
                    // const linkStyle = value?.link ? { color: '#284cc0' } : {}; // 是否带超链
                    const styleAttribute = pick(value, Object.keys(domStyleMap));
                    const textDom = (React.createElement("span", { className: classnames({
                            'cn-table-format-text-link': !!(value === null || value === void 0 ? void 0 : value.link),
                        }), style: styleAttribute, onClick: () => (value === null || value === void 0 ? void 0 : value.link) && window.open(value === null || value === void 0 ? void 0 : value.link) }, renderValueHandelEmpty(value.text)));
                    if (value === null || value === void 0 ? void 0 : value.toolTips) {
                        // toolsTips
                        const renderContent = (data) => {
                            return (React.createElement("div", null, data.map((item) => {
                                return React.createElement("div", null, item.desc);
                            })));
                        };
                        if (Array.isArray(value === null || value === void 0 ? void 0 : value.toolTips)) {
                            return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderContent(value.toolTips)));
                        }
                        else {
                            return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderValueHandelEmpty(value.toolTips)));
                        }
                    }
                    return textDom;
                }
                return renderValueHandelEmpty(value);
            },
        };
    },
    enumeration(column) {
        const { options } = column;
        return {
            cell(value) {
                var _a, _b, _c;
                return (React.createElement(CnAsyncSelect, { ...(options || {}), filterLocal: true, value: value, requestConfig: {
                        cacheKey: `${(_a = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _a === void 0 ? void 0 : _a.url}+${(_b = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _b === void 0 ? void 0 : _b.method}+${(_c = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _c === void 0 ? void 0 : _c.params}`,
                        staleTime: 5000,
                        ...((options === null || options === void 0 ? void 0 : options.requestConfig) || {}),
                    }, readOnly: true }));
            },
        };
    },
};
function getTag(val) {
    if (typeof val === 'string') {
        return (React.createElement("span", { className: 'cn-table-format-cell-tag cn-table-format-cell-tag-normal' },
            React.createElement("span", null, val)));
    }
    return (React.createElement("span", { className: `cn-table-format-cell-tag cn-table-format-cell-tag-${(val === null || val === void 0 ? void 0 : val.state) || 'normal'}` },
        React.createElement("span", null, (val === null || val === void 0 ? void 0 : val.value) || '')));
}
function getNumFromStr(val) {
    return isNumberStr(`${val}`) ? formatCurrency(`${val}`) : val;
}
function formatCurrency(val) {
    const [pre, suff] = val === null || val === void 0 ? void 0 : val.split('.');
    return `${Number(pre).toLocaleString('zh-CN')}${isNil(suff) ? `.${suff}` : ''}`;
    // return Number(val).toLocaleString('zh-CN');
    // const split = ',';
    // const [pre, suff] = `${parseFloat(val)}`.split('.');
    // const reverseArr = pre
    //   .split('')
    //   .reverse()
    //   .join('')
    //   .replace(/([0-9]{3})/g, `$1${split}`)
    //   .split('')
    //   .reverse();
    // if (reverseArr[0] === split) {
    //   reverseArr.splice(0, 1);
    // }
    // return `${reverseArr.join('')}${isFunction(suff?.slice) ? `.${suff?.slice?.(0, 2)}` : ''
    //   }`;
}
function formatDate(val = '0', interv = '-', dateFormat = 'YYYY-MM-DD') {
    if (interv !== '-') {
        const date = new Date(val !== null && val !== void 0 ? val : 0);
        return `${date.getFullYear()}${interv}${date.getMonth() + 1}${interv}${date.getDate()}`;
    }
    return dayjs(val).format(dateFormat);
}
function formatDateTime(val, { interv = '-', hasSecond = true }) {
    const date = new Date(val !== null && val !== void 0 ? val : 0);
    return `${formatDate(val, interv)} ${getTwoNumber(date.getHours())}:${getTwoNumber(date.getMinutes())}${hasSecond ? `:${getTwoNumber(date.getSeconds())}` : ''}`;
}
function getTwoNumber(val) {
    const num = parseInt(String(val), 10);
    return num < 10 && num >= 0 ? `0${num}` : num;
}
function renderText(value) {
    var _a;
    if (isPlainObject(value)) {
        // 对象传入，目前支持ToolTips,link
        const linkStyle = (value === null || value === void 0 ? void 0 : value.link) ? { color: '#284cc0' } : {}; // 是否带超链
        const styleAttribute = Object.keys(value).reduce((pre, cur) => {
            if (cur in domStyleMap) {
                pre[cur] = value[cur];
            }
            return pre;
        }, {});
        const textDom = (React.createElement("span", { style: { ...linkStyle, ...styleAttribute }, onClick: () => (value === null || value === void 0 ? void 0 : value.link) && window.open(value === null || value === void 0 ? void 0 : value.link) }, renderValueHandelEmpty((_a = value.value) !== null && _a !== void 0 ? _a : value.text)));
        if (value === null || value === void 0 ? void 0 : value.toolTips) {
            // toolsTips
            const renderContent = (data) => {
                return (React.createElement("div", null, data.map((item) => {
                    return React.createElement("div", null, item.desc);
                })));
            };
            if (Array.isArray(value === null || value === void 0 ? void 0 : value.toolTips)) {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderContent(value.toolTips)));
            }
            else {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderValueHandelEmpty(value.toolTips)));
            }
        }
        return textDom;
    }
    return renderValueHandelEmpty(value);
}
function renderLine(value, lineNumber) {
    const text = renderText(value);
    // let text;
    // if (isPlainObject(value)) {
    //   if (typeof value?.value === 'string') {
    //     text = value.value;
    //   }
    // } else if (typeof value === 'string') {
    //   text = value;
    // } else {
    //   try {
    //     text = JSON.stringify(value);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    return (React.createElement("div", { className: `ctmt-line ctmt-line-${lineNumber || 1}` },
        React.createElement(Tooltip, { v2: true, delay: 100, align: 't', trigger: React.createElement("span", null, text) }, text)));
}
function renderValueHandelEmpty(value) {
    if (isNil(value) || value === '') {
        return '- -';
    }
    return value;
}
export default CnTableCellFormat;
