import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { UploadList } from '../upload-list';
import { Box } from '@/components/fusion';
import { useOnRemove } from '../upload-core/hooks/use-on-remove';
import { UploadCore } from '../upload-core';
import { UploadButton } from '../upload-button';
export const CnUploadView = (props) => {
    const { style, className, onPreview, onDownload, onRemove: _onRemove, showRemove, showPreview, showDownload, formatter, directory, method, headers, beforeUpload, customRequest, withCredentials, openFileDialogOnClick, buttonText, readOnly, onError, description, buttonProps, name, multiple = false, action, data, accept, showUploadList, disabled, limit, value: originValue, defaultValue, onChange, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = props;
    const uploadRef = useRef();
    const onChangeRef = useRef(onChange);
    const onRemoveRef = useRef(_onRemove);
    const [value, setValue] = useState(() => {
        return originValue || defaultValue || [];
    });
    useUpdateEffect(() => {
        onChangeRef.current = onChange;
        onRemoveRef.current = _onRemove;
    }, [onChange, _onRemove]);
    useUpdateEffect(() => {
        setValue(originValue || []);
    }, [originValue]);
    const onRemove = useOnRemove({
        setValue,
        uploadRef,
        onChangeRef,
        onRemoveRef,
    });
    const isDisabledUpload = disabled || value.length >= limit;
    return (React.createElement("div", { "data-name": "CnUpload", style: style, className: classNames(className, 'cn-ui-upload', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': isDisabledUpload,
        }) },
        React.createElement(Box, { direction: "row", align: "center", className: "cn-ui-upload-core" },
            !readOnly && (React.createElement(UploadCore, { formatter: formatter, directory: directory, method: method, headers: headers, beforeUpload: beforeUpload, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onError: onError, name: name, multiple: multiple, action: action, data: data, accept: accept, disabled: isDisabledUpload, onChange: onChange, setValue: setValue },
                React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }))),
            React.createElement("div", { className: "cn-ui-upload-description" }, description)),
        showUploadList ? (React.createElement(UploadList, { readOnly: readOnly, value: value, limit: limit, onPreview: onPreview, onDownload: onDownload, onRemove: onRemove, showRemove: !disabled && showRemove, showPreview: showPreview, showDownload: showDownload, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })) : null));
};
CnUploadView.defaultProps = {
    limit: 999,
    readOnly: false,
    showRemove: true,
    showPreview: true,
    showDownload: true,
    showUploadList: true,
};
