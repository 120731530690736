import isFunction from 'lodash/isFunction';
import flattenDeep from 'lodash/flattenDeep';
import cloneDeep from 'lodash/cloneDeep';
/**
 * 获取字符串字节长
 */
export function getByteLen(val) {
    let len = 0;
    for (let i = 0; i < val.length; i++) {
        const a = val.charCodeAt(i);
        if (a >= 0 && a <= 128) {
            len += 1;
        }
        else {
            len += 2;
        }
    }
    return len;
}
/**
 * 截取字符串，中文占2字节，西文占1字节
 * 例如 subCharStr('测试aaa一段很bbb长字符ccc串测试一段很长字符串', 15)
 * 返回 '测试aaa一段很bb...'
 */
export function subCharStr(str, n) {
    if (typeof str !== 'string')
        return str;
    const r = /[^\x00-\xff]/g;
    if (str.replace(r, 'cc').length <= n) {
        return str;
    }
    const m = Math.floor(n / 2);
    for (let i = m; i < str.length; i++) {
        if (str.substr(0, i).replace(r, 'cc').length >= n) {
            return `${str.substr(0, i)}...`;
        }
    }
    return str;
}
export function safeCallFunction(fun, ...rest) {
    var _a;
    if (isFunction(fun)) {
        return (_a = fun(...rest)) !== null && _a !== void 0 ? _a : true;
    }
    return undefined;
}
export function flattenDeepChildren(collection) {
    if (!Array.isArray(collection)) {
        return [];
    }
    const getTail = (item) => {
        if ((item === null || item === void 0 ? void 0 : item.children) && (item === null || item === void 0 ? void 0 : item.children.length) > 0) {
            return [item, item === null || item === void 0 ? void 0 : item.children.map((m) => getTail(m))];
        }
        else {
            return [item];
        }
    };
    const cloneCollection = cloneDeep(collection);
    const result = flattenDeep(cloneCollection.map((m) => getTail(m)));
    return Array.isArray(result) ? result : [];
    // return find(flattenDeepChildren, target);
}
export function isPromise(obj) {
    return (!!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function');
}
