import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { Box } from '@/components/fusion';
import { MiniUploadList } from '../upload-list/mini-file-list';
import { useOnRemove } from '../upload-core/hooks/use-on-remove';
import { UploadCore } from '../upload-core';
import { CnTooltip } from '../../../cn-tooltip';
import { UploadButton } from '../upload-button';
export const CnMiniUploadView = (props) => {
    const { style, className, onPreview, onDownload, onRemove: _onRemove, showRemove, showPreview, showDownload, formatter, directory, method, headers, beforeUpload, customRequest, withCredentials, openFileDialogOnClick, buttonText, readOnly, onError, description, buttonProps, name, multiple = false, action, data, accept, showUploadList = true, disabled, limit = 999, value: originValue, defaultValue, onChange, popupProps, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = props;
    const uploadRef = useRef();
    const onRemoveRef = useRef(_onRemove);
    const onChangeRef = useRef(onChange);
    const [value, setValue] = useState(() => {
        if (originValue === null || originValue === void 0 ? void 0 : originValue.length)
            return originValue;
        if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length)
            return defaultValue;
        return [];
    });
    useEffect(() => {
        onChangeRef.current = onChange;
        onRemoveRef.current = _onRemove;
    }, [onChange, _onRemove]);
    useUpdateEffect(() => {
        setValue(originValue || []);
    }, [originValue]);
    const onRemove = useOnRemove({
        setValue,
        uploadRef,
        onChangeRef,
        onRemoveRef,
    });
    const isDisabledUpload = disabled || value.length >= limit;
    return (React.createElement("div", { "data-name": "CnMiniUpload", style: style, className: classNames(className, 'cn-ui-upload', 'cn-ui-upload-mini', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': isDisabledUpload,
        }) },
        React.createElement(Box, { direction: "row", align: "center", className: "cn-ui-upload-core" }, readOnly ? null : (React.createElement(UploadCore, { formatter: formatter, directory: directory, method: method, headers: headers, beforeUpload: beforeUpload, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onError: onError, name: name, multiple: multiple, action: action, data: data, accept: accept, disabled: isDisabledUpload, onChange: onChange, setValue: setValue }, description ? (React.createElement(CnTooltip, { trigger: React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }) }, description)) : (React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }))))),
        showUploadList ? (React.createElement(MiniUploadList, { popupProps: popupProps, readOnly: readOnly, value: value, limit: limit, onPreview: onPreview, onDownload: onDownload, onRemove: onRemove, showRemove: !disabled && showRemove, showPreview: showPreview, showDownload: showDownload, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })) : null));
};
