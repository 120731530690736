import * as React from 'react';
import cn from 'classnames';
import { useControllableValue } from 'ahooks';
import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { Header } from './header';
import { SideBar } from './side-bar';
import Content from './content/content';
import './shell.scss';
export const Shell = withI18n((props) => {
    const { children, headerVisible, headerProps, sideBarVisible, sideBarProps, theme, } = props;
    const [isSideBarFold, setSideBarFold] = useControllableValue(sideBarProps, {
        defaultValue: false,
        valuePropName: 'menuCollapse',
        defaultValuePropName: 'defaultMenuCollapse',
        trigger: 'onToggleMenuCollapse',
    });
    return (React.createElement("div", { "data-name": "CnShell", className: cn({
            'cn-ui-shell': true,
            [`cn-ui-shell-${theme}`]: true,
            'hide-sidebar': !sideBarVisible,
            'hide-top-bar': !headerVisible,
        }) },
        headerVisible && React.createElement(Header, { ...headerProps }),
        React.createElement("div", { style: { display: 'flex' } },
            sideBarVisible && (React.createElement(SideBar, { isFold: isSideBarFold, setFold: setSideBarFold, ...(sideBarProps || {}) })),
            React.createElement(Content, { isSideBarFold: sideBarVisible && isSideBarFold }, children))));
}, {
    locale,
    componentName: 'CnShell',
});
Shell.defaultProps = {
    sideBarVisible: true,
    headerVisible: true,
    theme: 'normal',
};
