import * as React from 'react';
import $i18n, { pandaEvent } from 'panda-i18n';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
// import NEXT_LOCALE from '../../../locale/cn-next';
import NEXT_LOCALE from '@/locales/cn-next';
import Cookies from 'js-cookie';
import { useControllableValue, useCookieState } from 'ahooks';
import defaultsDeep from 'lodash/defaultsDeep';
import { events } from '../../components/cn-utils';
/**
 * 用于控制 useValue 能力
 * @param props
 * @param defaultValue
 * @param options
 */
export function useValueState(props, defaultValue, options) {
    const { formatter } = options || {};
    const isControlled = 'value' in props;
    const initValue = React.useMemo(() => {
        let v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        if (typeof formatter === 'function') {
            v = formatter(v);
        }
        return v;
    }, []);
    const [value, setValue] = React.useState(initValue);
    React.useEffect(() => {
        if (isControlled && value !== props.value) {
            let v = isNil(props.value) ? defaultValue : props.value;
            if (typeof formatter === 'function') {
                v = formatter(v);
            }
            setValue(v);
        }
    }, [props.value]);
    return [value, setValue, isControlled];
}
export function useDerivedValue(props, defaultValue) {
    const isControlled = 'value' in props;
    const initValue = React.useMemo(() => {
        let v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        return v;
    }, []);
    const [value, setValue] = React.useState(initValue);
    const [prevValue, setPrevValue] = React.useState(null);
    if (isControlled && !isEqual(prevValue, props.value)) {
        setValue(isNil(props.value) ? defaultValue : props.value);
        setPrevValue(props.value);
    }
    return [value, setValue, isControlled];
}
/** 使用next组件的翻译文案，并支持自动监听语种的变化 */
export const useNextLocale = (componentName) => {
    const [locale, setLocale] = React.useState(() => {
        const _lang = $i18n.getLang();
        return NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null;
    });
    pandaEvent.on('CHANGE_LANG', (_lang) => {
        setLocale(NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null);
    });
    return locale;
};
export function useSizeChange(props) {
    const SIZE_CHANGE = 'SIZECHANGE';
    const [cookieSize] = useCookieState('CNUICOOKIESIZE');
    const [size, setSize] = useControllableValue(props, {
        valuePropName: 'size',
        defaultValue: cookieSize || 'medium',
        defaultValuePropName: 'defaultSize',
        trigger: 'sizeChange',
    });
    React.useEffect(() => {
        events.on(SIZE_CHANGE, setSize);
        return () => {
            events.off(SIZE_CHANGE, setSize);
        };
    }, []);
    return {
        size,
        setSize,
    };
}
export function setGlobalSize(size, options = {}) {
    defaultsDeep(options, { cookieKey: 'CNUICOOKIESIZE' });
    // Cookies.set(options.cookieKey, size, omit(options, ['cookieKey']));
    // const [cookieSize, SetCookieSize] = useCookieState(
    //   options.cookieKey,
    //   options,
    // );
    if (['small', 'medium', 'large'].includes(size)) {
        Cookies.set(options.cookieKey, size, omit(options, ['cookieKey']));
        events.emit('SIZECHANGE', size);
    }
}
export function useAsyncLoading(props) {
    const [loading, setLoading] = React.useState(false);
    if ((props === null || props === void 0 ? void 0 : props.asyncLoading) && isFunction(props === null || props === void 0 ? void 0 : props.onClick)) {
        const wrapOnClick = new Proxy(props.onClick, {
            apply(arget, object, args) {
                setLoading(true);
                const result = arget(...args);
                if (isPromise(result)) {
                    result.finally(() => {
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                }
                return result;
            },
        });
        return {
            onClick: wrapOnClick,
            loading,
        };
    }
    return { props };
}
