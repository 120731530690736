import * as React from 'react';
import cx from 'classnames';
import $i18n, { withI18n } from 'panda-i18n';
import locale from '@/locales';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnLoading } from '@/components/cn-loading';
import NextTreeSelect from './tree-select';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnReadOnly } from '@/components/cn-read-only';
import { useCnRequest, useValueState } from '@/components/cn-utils';
import { findDeep, getCheckAllValues } from './utils';
import './index.scss';
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: (React.createElement("div", { className: "cn-tree-select-error" }, $i18n.get({
            id: 'RequestDataException',
            dm: '请求数据异常',
            ns: 'CnTreeSelect',
        }))),
    };
}
function handleLoading(loading, data) {
    const ContentLoading = () => {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(CnLoading, { size: "medium" })));
    };
    const IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
export const CnTreeSelect = withI18n(React.forwardRef((props, ref) => {
    var _a, _b, _c, _d;
    const { className, readOnly, readOnlyProps, requestConfig, treeProps, showCheckAll, popupClassName: popupClassNameProps, onVisibleChange: onVisibleChangeProps, onSearchClear: onSearchClearProps, onSearch: onSearchProps, onChange: onChangeProps, enableRemoteLazyLoad, ...otherProps } = props;
    const [value, setValue, isControlled] = useValueState(props, undefined);
    const treeRef = React.useRef();
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [searching, setSearching] = React.useState(false);
    const [, forceUpdate] = React.useState(1);
    const existValues = React.useRef();
    const checkAllValuesRef = React.useRef([]);
    const selectRef = React.useRef(null);
    const remoteLazyLoadItemRef = React.useRef();
    const insertSelectProps = {};
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    const { run, runAsync, error, data, loading, mutate } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sendRequest: run,
            // 为了给外部正确使用 forceUpdate
            forceUpdate: () => {
                forceUpdate((s) => (s + 1) % 32);
            },
            ...((_a = selectRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    }, [selectRef]);
    const shouldShowCheckAll = showCheckAll && (props.multiple || props.treeCheckable);
    const maxTagPlaceholder = (selectedValues) => {
        const trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
            $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }),
            `${selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length}`,
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnAsyncSelect',
            })));
        const labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map((obj) => {
            if (typeof obj === 'object') {
                return obj.label;
            }
            return obj;
        });
        return (React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', ')));
    };
    React.useEffect(() => {
        // existValues !== props.value，则清空
        if (!('value' in props))
            return;
        let ev = existValues.current;
        ev = Array.isArray(ev) ? ev.map((i) => i.value) : ev === null || ev === void 0 ? void 0 : ev.value;
        if (!isEqual(ev, props.value)) {
            existValues.current = undefined;
        }
    }, [props.value]);
    React.useEffect(() => {
        if (otherProps === null || otherProps === void 0 ? void 0 : otherProps.children)
            return;
        if (loading)
            return;
        setInnerDataSource((previousDataSource) => {
            var _a;
            const rst = [];
            const ev = existValues.current;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                const valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return [...previousDataSource];
            }
            if (Array.isArray(ev)) {
                ev.length > 0 &&
                    ev.map((i) => {
                        if (!findDeep(data, 'value', i.value)) {
                            rst.push({
                                value: i.value,
                                label: i.label,
                            });
                        }
                    });
                return data.concat(rst);
            }
            if (ev && !findDeep(data, 'value', ev.value)) {
                rst.push({
                    value: ev.value,
                    label: ev.label,
                });
                return data.concat(rst);
            }
            return data;
        });
    }, [data]);
    // treenode形式兼容
    if (!(otherProps === null || otherProps === void 0 ? void 0 : otherProps.children)) {
        insertSelectProps.dataSource = innerDataSource || [];
    }
    if (isRemoteDataSource) {
        insertSelectProps.autoWidth = false;
    }
    const errorInfo = handleErrorInfo(error);
    const loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // 开启远程异步加载数据时，关闭搜索功能
    if (enableRemoteLazyLoad) {
        otherProps.showSearch = false;
        otherProps.treeLoadData = ({ props: item }) => {
            remoteLazyLoadItemRef.current = item;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).catch((err) => {
                console.log(err);
            });
        };
    }
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    const enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = (inputValue) => {
            setSearching(true);
            run({
                [requestConfig.searchKey || 'key']: inputValue,
            });
        };
    }
    const onVisibleChange = (visible, type) => {
        if (typeof onVisibleChangeProps === 'function') {
            onVisibleChangeProps(visible, type, run);
        }
    };
    const onSearchClear = (actionType) => {
        if (typeof onSearchClearProps === 'function') {
            onSearchClearProps(actionType, run);
        }
    };
    const onChange = (v, d) => {
        if (!isControlled) {
            setValue(v);
        }
        checkAllValuesRef.current = getRealCheckAllValues();
        setCheckAll(isEqual(sortBy(v), sortBy(checkAllValuesRef.current)));
        if (isRemoteDataSource) {
            existValues.current = d;
            if (!v || !v.length) {
                run({
                    [requestConfig.searchKey || 'key']: '',
                });
            }
        }
        if (typeof onChangeProps === 'function') {
            onChangeProps(v, d);
        }
    };
    const onSearch = (searchedValue) => {
        setSearching(true);
        if (typeof onSearchProps === 'function') {
            onSearchProps(searchedValue);
        }
    };
    // 获取处理后的全选值
    const getRealCheckAllValues = () => {
        var _a, _b;
        if (!shouldShowCheckAll)
            return [];
        let checkAllValues = getCheckAllValues((_a = treeRef.current) === null || _a === void 0 ? void 0 : _a.treeChildren);
        if (props.treeCheckable && !props.treeCheckStrictly) {
            checkAllValues = (_b = selectRef.current) === null || _b === void 0 ? void 0 : _b.getValueForSelect(checkAllValues);
        }
        return checkAllValues;
    };
    React.useEffect(() => {
        // onSearch 触发并 rerender 之后才能获取实际的 popup item
        if (!searching)
            return;
        setSearching(false);
        checkAllValuesRef.current = getRealCheckAllValues();
        setCheckAll(isEqual(sortBy(value), sortBy(checkAllValuesRef.current)));
    }, [searching, value]);
    const handleClickCheckAll = (checked) => {
        var _a;
        setCheckAll(checked);
        if (!shouldShowCheckAll || !treeRef.current) {
            return;
        }
        if (checked) {
            checkAllValuesRef.current = getRealCheckAllValues();
            onChange(checkAllValuesRef.current, (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.getData(checkAllValuesRef.current));
        }
        else {
            onChange([], []);
        }
    };
    const renderPreview = (values) => {
        return (React.createElement(CnReadOnly, { value: values, valueSeparator: " / ", type: "enum", ...{
                ...pick(props, [
                    'addonBefore',
                    'innerBefore',
                    'addonTextBefore',
                    'addonTextAfter',
                    'innerAfter',
                    'addonAfter',
                ]),
                ...readOnlyProps,
            } }));
    };
    const minWidth = ((_d = (_c = (_b = (_a = selectRef === null || selectRef === void 0 ? void 0 : selectRef.current) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b._instance) === null || _c === void 0 ? void 0 : _c.selectDOM) === null || _d === void 0 ? void 0 : _d.offsetWidth) || 0;
    const cnTreeProps = {
        ...treeProps,
        ref: (r) => {
            treeRef.current = r;
        },
    };
    if (shouldShowCheckAll) {
        cnTreeProps.footer = (React.createElement(CnCheckbox, { checked: checkAll, onChange: handleClickCheckAll, label: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnTreeSelect' }) }));
    }
    let popupClassName = popupClassNameProps;
    if (cnTreeProps.header) {
        popupClassName = cx(popupClassName, {
            'with-header': true,
        });
    }
    if (cnTreeProps.footer) {
        popupClassName = cx(popupClassName, {
            'with-footer': true,
        });
    }
    return (React.createElement(NextTreeSelect, { "data-name": "CnTreeSelect", className: cx({
            'cn-ui-tree-select': true,
        }, className), ref: (r) => {
            selectRef.current = r;
        }, preserveNonExistentValue: isRemoteDataSource, isPreview: readOnly, renderPreview: renderPreview, maxTagPlaceholder: maxTagPlaceholder, onVisibleChange: onVisibleChange, onChange: onChange, onSearchClear: onSearchClear, onSearch: onSearch, popupClassName: popupClassName, treeProps: cnTreeProps, ...insertSelectProps, ...omit(otherProps, ['$i18n']), value: value, popupStyle: {
            minWidth,
        } }));
}), {
    componentName: 'CnTreeSelect',
    locale,
    forwardRef: true,
});
CnTreeSelect.displayName = 'CnTreeSelect';
CnTreeSelect.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    requestConfig: {},
    readOnlyProps: {
        valueSeparator: ' / ',
        type: 'enum',
    },
};
