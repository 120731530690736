import * as React from 'react';
import { Input as NextInput } from '@/components/fusion';
import pick from 'lodash/pick';
import { CnReadOnly } from '@/components/cn-read-only';
import { useDerivedValue, HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils';
const renderPreview = (value, readOnlyProps, props) => {
    // if (value === undefined) return null;
    return (React.createElement(CnReadOnly, { value: value, ...{
            ...pick(props, [
                'addonBefore',
                'innerBefore',
                'addonTextBefore',
                'addonTextAfter',
                'innerAfter',
                'addonAfter',
            ]),
            ...readOnlyProps,
        } }));
};
function nextCompMap(Comp, ComponentName) {
    const Component = React.forwardRef((props, ref) => {
        const { readOnly, readOnlyProps, ...otherProps } = props;
        const [value, setValue, isControlled] = useDerivedValue(props, undefined);
        const onChange = (newValue, e) => {
            if (!isControlled) {
                setValue(newValue);
            }
            props.onChange && props.onChange(newValue, e);
        };
        if (readOnly) {
            return renderPreview(value, readOnlyProps, props);
        }
        return (React.createElement(Comp, { "data-name": "CnInput", ref: ref, ...otherProps, value: value, onChange: onChange }));
    });
    Component.displayName = ComponentName;
    return Component;
}
export const CnInputBase = nextCompMap(NextInput, 'CnInput');
export const CnInput = React.forwardRef((props, ref) => {
    const plugin = React.useMemo(() => {
        const plugin = new Plugin('CnInput', pluginManager);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnInput')
            .map((item) => item.method));
        return plugin;
    }, []);
    const plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnInputBase, { ...props, ref: ref });
    }
    return HocBaseComponents(CnInputBase, { props, plugins, ref });
});
export const CnInputTextAreaBase = nextCompMap(NextInput.TextArea, 'CnInputTextArea');
export const CnInputTextArea = React.forwardRef((props, ref) => {
    const plugin = React.useMemo(() => {
        const plugin = new Plugin('CnInputTextArea', pluginManager);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnInputTextArea')
            .map((item) => item.method));
        return plugin;
    }, []);
    const plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnInputTextAreaBase, { ...props, ref: ref });
    }
    return HocBaseComponents(CnInputTextAreaBase, { props, plugins, ref });
});
export const CnInputGroup = NextInput.Group;
CnInputGroup.displayName = 'CnInputGroup';
