import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import CNTableView from './view';
import { componentName } from './const';
export * from './view';
const CnTable = withI18n(CNTableView, {
    componentName,
    locale,
});
CnTable.displayName = componentName;
export { CnTable };
