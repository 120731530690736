import * as React from 'react';
import cx from 'classnames';
import $i18n from 'panda-i18n';
import { ExpressionScope, FormConsumer, FormProvider, } from '@formily/react';
import { createForm, setValidateLanguage } from '@formily/core';
import { Box } from '@fusion/lib';
import { Submit } from '@/form/submit';
import { Reset } from '@/form/reset';
import { createScope } from '@/form/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnPageFooter } from '@/components/cn-page-footer';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnAnchor } from '@/components/cn-anchor';
import { CnGrid } from '@/components/grid';
import { useSizeChange } from '@/components/cn-utils';
import ResizeObserver from 'resize-observer-polyfill';
import isObject from 'lodash/isObject';
const { CnRow, CnCol } = CnGrid;
export const CnForm = (props) => {
    const { formProps, components, schema, onSubmit, onReset, scope, footerConfig, hasFooterSubmit, form: initForm, cnFormStepInstance, children, isAnchorForm, anchorProps, } = props;
    const { size, setSize } = useSizeChange({});
    const formLayoutProps = {
        size,
        ...props.formLayoutProps
    };
    if (size === 'small'
        && (!formLayoutProps.labelCol || isObject(formLayoutProps.labelCol))) {
        formLayoutProps.labelCol = {
            fixedSpan: 5,
            ...(formLayoutProps.labelCol || {}),
        };
    }
    const { rightFixedSpan = 10, headingLevel = [1, 2, 3], ...otherAnchorProps } = anchorProps || {};
    const lang = $i18n.getLang();
    const RealSchemaField = React.useMemo(() => createScope(scope), [scope]);
    const anchorFormRef = React.useRef();
    const [anchorFormMountedStyle, setAnchorFormMountedStyle] = React.useState({});
    const resizeObserverRef = React.useRef();
    const form = React.useMemo(() => {
        // 如果涉及到状态的更新，须传入form实例
        if (initForm)
            return initForm;
        return createForm(formProps);
    }, [initForm, schema]);
    React.useEffect(() => {
        setValidateLanguage(lang);
    }, [lang]);
    const setAnchorStyle = () => {
        if (!anchorFormRef.current || !isAnchorForm)
            return;
        // 兼容 drawer 等容器的动画
        setTimeout(() => {
            const { left = 0, width = 0, top = 0, } = anchorFormRef.current.getBoundingClientRect();
            if (top < 0)
                return;
            setAnchorFormMountedStyle({
                top: top,
                left: left + width,
                right: 'initial',
            });
        }, 500);
    };
    React.useEffect(() => {
        if (!anchorFormRef.current || !isAnchorForm)
            return;
        setAnchorStyle();
        resizeObserverRef.current = new ResizeObserver(() => {
            setAnchorStyle();
        });
        resizeObserverRef.current.observe(anchorFormRef.current);
        return () => {
            resizeObserverRef.current.disconnect();
        };
    }, []);
    function createFormFooter(footerProps) {
        const { submitProps, resetProps, className, customRender, ...otherProps } = footerProps || {};
        const classes = cx('cn-ui-form-footer', className);
        if (cnFormStepInstance) {
            return (React.createElement(FormConsumer, null, () => (React.createElement(CnPageFooter, { className: classes, leftSlot: React.createElement("div", { className: "cn-ui-form-footer-header" }, cnFormStepInstance.allowBack && (React.createElement(CnButton, { size: "large", onClick: () => {
                        cnFormStepInstance.back();
                    } },
                    React.createElement(CnIcon, { type: "arrow-left" }),
                    $i18n.get({
                        id: 'PreviousStep',
                        dm: '上一步',
                        ns: 'CnForm',
                    })))), rightSlot: React.createElement(Box, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                    React.createElement(Reset, { size: "large", ...resetProps, onReset: onReset }),
                    !cnFormStepInstance.allowNext && (React.createElement(Submit, { size: "large", ...submitProps, onSubmit: onSubmit, disabled: cnFormStepInstance.allowNext })),
                    cnFormStepInstance.allowNext && (React.createElement(CnButton, { size: "large", onClick: () => {
                            cnFormStepInstance.next();
                        } },
                        React.createElement(CnIcon, { type: "arrow-right" }),
                        $i18n.get({ id: 'Next', dm: '下一步', ns: 'CnForm' })))), ...otherProps }))));
        }
        if (customRender) {
            return (React.createElement(CnPageFooter, { className: classes, rightSlot: React.createElement(FormConsumer, null, customRender), ...otherProps }));
        }
        return (React.createElement(CnPageFooter, { className: classes, rightSlot: React.createElement(Box, { className: "cn-ui-form-footer-footer", spacing: 8, direction: "row" },
                React.createElement(Reset, { size: "large", ...resetProps, onReset: onReset }),
                React.createElement(Submit, { size: "large", ...submitProps, onSubmit: onSubmit })), ...otherProps }));
    }
    const formRender = () => {
        return (React.createElement(FormProvider, { form: form },
            React.createElement(ExpressionScope, { value: { $$form: form } },
                React.createElement(CnFormLayout, { "data-name": "CnForm", ...formLayoutProps },
                    React.createElement(RealSchemaField, { schema: schema, components: components, scope: scope }),
                    children,
                    (hasFooterSubmit || footerConfig) && createFormFooter(footerConfig)))));
    };
    if (isAnchorForm) {
        return (React.createElement("div", { className: 'cn-ui-anchor-form', ref: anchorFormRef },
            React.createElement(CnRow, null,
                React.createElement(CnCol, null, formRender()),
                React.createElement(CnCol, { fixedSpan: rightFixedSpan })),
            React.createElement(CnAnchor, { style: anchorFormMountedStyle, headingSelector: headingLevel.map((level) => {
                    if (level === 1)
                        return '.cn-ui-card-header-title .cn-ui-form-card-title';
                    if (level === 2)
                        return '.cn-ui-card-sub-card-header-header .cn-ui-form-card-title';
                    if (level === 3)
                        return '.cn-ui-card-sub-area-card-title-header .cn-ui-form-card-title';
                    return false;
                }).filter(Boolean).join(','), ...otherAnchorProps })));
    }
    return formRender();
};
CnForm.defaultProps = {
    hasFooterSubmit: false,
};
